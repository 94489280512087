import { ReactNode } from "react";
import clsx from "clsx";

import { IconShape } from "../../../icons/svgr";

interface Props {
  className?: string;
  children: ReactNode;
  Icon: ReactNode;
}

export default function ListWithIconItem({ className, children, Icon }: Props) {
  return (
    <div className={clsx(className, "flex gap-md")}>
      <div className="relative flex-none w-2xl h-2xl">
        <IconShape className="absolute -right-[4px] -bottom-[8px]" />
        <div className="relative text-primary z-[1]">{Icon}</div>
      </div>
      <div className="flex flex-col">{children}</div>
    </div>
  );
}
