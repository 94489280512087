import { ReactNode } from "react";
import clsx from "clsx";

export enum Orientation {
  "horizontal",
  "vertical",
}

interface Props {
  className?: string;
  children: ReactNode;
  orientation?: Orientation;
}

export default function ListWithIcon({
  className,
  children,
  orientation,
}: Props) {
  return (
    <div
      className={clsx(
        className,
        "gap-x-md gap-y-xl text-left",
        orientation === Orientation["horizontal"] ? "grid" : "flex flex-wrap",
      )}
    >
      {children}
    </div>
  );
}
