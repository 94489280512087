"use client";

import clsx from "clsx";
import { useTranslations } from "next-intl";

import { ListWithIcon, ListWithIconItem, Orientation } from "../list-with-icon";
import {
  IconFirst,
  IconHandHoldingBox,
  IconHrPerson,
  IconProcess,
} from "../../icons/svgr";
import AOSComponent from "../aos";
import { Section } from "../section";

import { bodyText } from "@/components/primitives";
import ErrorBoundary from "@/components/error-boundary";

interface Props {
  className?: string;
}

function FeaturesComponent({ className }: Props) {
  const t = useTranslations("features");

  return (
    <AOSComponent>
      <Section
        className={clsx(
          className,
          "flex flex-wrap lg:gap-2xl py-section-small_ lg:py-section_",
        )}
      >
        <ListWithIcon
          className="grid-cols-2 lg:grid-cols-4"
          orientation={Orientation.horizontal}
        >
          <ListWithIconItem
            Icon={
              <div data-aos="fade-zoom">
                <IconFirst />
              </div>
            }
            className="flex-col"
          >
            <div data-aos="fade-slide-up">
              <div className={bodyText({ size: "2xl", weight: "600" })}>
                {t("feature-1.heading")}
              </div>
              <div
                className={clsx(
                  bodyText({ size: "xl", color: "gray-light" }),
                  "mt-3",
                )}
              >
                {t("feature-1.text")}
              </div>
            </div>
          </ListWithIconItem>
          <ListWithIconItem
            Icon={
              <div data-aos="fade-zoom" data-aos-delay="500">
                <IconProcess />
              </div>
            }
            className="flex-col"
          >
            <div data-aos="fade-slide-up" data-aos-delay="500">
              <div className={bodyText({ size: "2xl", weight: "600" })}>
                {t("feature-2.heading")}
              </div>
              <div
                className={clsx(
                  bodyText({ size: "xl", color: "gray-light" }),
                  "mt-3",
                )}
              >
                {t("feature-2.text")}
              </div>
            </div>
          </ListWithIconItem>
          <ListWithIconItem
            Icon={
              <div data-aos="fade-zoom" data-aos-delay="1000">
                <IconHandHoldingBox />
              </div>
            }
            className="flex-col"
          >
            <div data-aos="fade-slide-up" data-aos-delay="1000">
              <div className={bodyText({ size: "2xl", weight: "600" })}>
                {t("feature-3.heading")}
              </div>
              <div
                className={clsx(
                  bodyText({ size: "xl", color: "gray-light" }),
                  "mt-3",
                )}
              >
                {t("feature-3.text")}
              </div>
            </div>
          </ListWithIconItem>
          <ListWithIconItem
            Icon={
              <div data-aos="fade-zoom" data-aos-delay="1500">
                <IconHrPerson />
              </div>
            }
            className="flex-col"
          >
            <div data-aos="fade-slide-up" data-aos-delay="1500">
              <div className={bodyText({ size: "2xl", weight: "600" })}>
                {t("feature-4.heading")}
              </div>
              <div
                className={clsx(
                  bodyText({ size: "xl", color: "gray-light" }),
                  "mt-3",
                )}
              >
                {t("feature-4.text")}
              </div>
            </div>
          </ListWithIconItem>
        </ListWithIcon>
      </Section>
    </AOSComponent>
  );
}

export default function Features() {
  return (
    <ErrorBoundary>
      <FeaturesComponent />
    </ErrorBoundary>
  );
}
